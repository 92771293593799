<template>
  <div>
    <v-row>
      <v-col>
        <v-card flat tile>
          <v-card-title>
            {{ $t("FAQ") }}
          </v-card-title>
          <v-card-text> Coming soon: frequently asked questions </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
