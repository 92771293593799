<template>
  <div>
    <v-row>
      <v-col>
        <v-card flat tile>
          <v-card-title>
            <h1 class="primary--text">{{ $t("contact") }}</h1>
          </v-card-title>
          <v-card-text class="pa-4">
            <v-row>
              <v-col cols="12" md="8">
                <h2 class="primary--text">
                  <img
                    class="mr-2"
                    contain
                    src="@/assets/arrow-single.png"
                    transition="scale-transition"
                    height="20"
                  />
                  {{ $t("contactPageTitle") }}
                </h2>
                <div class="black--text">
                  <div v-html="$t('contactPageText')"></div>
                </div>
                <div>
                  <v-btn
                    class="primary btnWithHoverArrow"
                    href="https://isopartner.nl/nl/firelog-solution-selector#contact"
                    target="_blank"
                  >
                    {{ $t("contactUs") }}
                  </v-btn>
                </div>
              </v-col>
              <v-col cols="12" md="4" lg="3" xl="2">
                <div class="primary white--text pa-2">
                  <div>
                    <img
                      class="mr-2"
                      contain
                      src="@/assets/IsopartnerLogo-White.png"
                      transition="scale-transition"
                      height="20"
                    />
                  </div>
                  <div class="mt-3">
                    <div><b>Vestiging Wieringerwerf</b></div>
                    <div>Westrak 210</div>
                    <div>1771 SV Wieringerwerf</div>
                    <div>
                      <v-icon color="white" small class="mr-2">
                        mdi-phone-in-talk
                      </v-icon>
                      <a href="tel:+310227604747" class="white--text">
                        +31 0227 60 47 47
                      </a>
                    </div>
                    <div>
                      <v-icon color="white" small class="mr-2">
                        mdi-email
                      </v-icon>
                      <a href="mailto:pfp@isopartner.nl" class="white--text">
                        pfp@isopartner.nl
                      </a>
                    </div>
                  </div>
                  <!-- <div class="mt-3">
                    <div>
                      <v-icon color="white" small class="mr-2">
                        mdi-map-marker
                      </v-icon>
                      <a
                        href="geo:52.8387904,5.0086801;u=35"
                        class="white--text"
                      >
                        Navigeer naar dit adres
                      </a>
                    </div>
                  </div> -->
                  <div class="mt-3">
                    <div>{{ $t("contactOpeningHours") }}</div>
                    <ul>
                      <li>Ma - Vr: 8:00-17:15 uur</li>
                    </ul>
                  </div>
                </div>
              </v-col>
              <v-col v-if="$vuetify.breakpoint.lgAndUp" lg="1"></v-col>
              <v-col v-if="$vuetify.breakpoint.xlOnly" xl="1"></v-col>
            </v-row>
            <v-row>
              <v-col>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2410.0884948450703!2d5.008680076962949!3d52.8387904125782!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c8b39c188a15bb%3A0x9be2553783a761cd!2sWestrak%20210%2C%201771%20SV%20Wieringerwerf%2C%20Nederland!5e0!3m2!1snl!2sbe!4v1718018883169!5m2!1snl!2sbe"
                  width="100%"
                  height="400px"
                  style="border: 0"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.btnWithHoverArrow {
  position: relative;
  display: inline-flex;
  align-items: center;
  min-height: 33px;
  padding: 5px 20px;
  margin: 0px 18px 0px 10px;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  transition: -webkit-clip-path 0.5s ease;
  transition: clip-path 0.5s ease;
  transition: clip-path 0.5s ease, -webkit-clip-path 0.5s ease;
}
.btnWithHoverArrow::before,
.btnWithHoverArrow::after {
  opacity: 1;
  box-shadow: none;
  position: absolute;
  top: 0;
  content: "";
  width: 10px;
  height: 100%;
  background-color: inherit;
  transition: -webkit-clip-path 0.5s ease;
  transition: clip-path 0.5s ease;
  transition: clip-path 0.5s ease, -webkit-clip-path 0.5s ease;
}

.btnWithHoverArrow::before {
  left: -10px;
  -webkit-clip-path: polygon(
    11px 0%,
    11px 50%,
    11px 101%,
    0% 101%,
    0% 50%,
    0% 0%
  );
  clip-path: polygon(11px 0%, 11px 50%, 11px 101%, 0% 101%, 0% 50%, 0% 0%);
}

.btnWithHoverArrow::after {
  right: -10px;
  -webkit-clip-path: polygon(
    100% 0%,
    100% 50%,
    100% 101%,
    -1px 101%,
    -1px 50%,
    -1px 0%
  );
  clip-path: polygon(
    100% 0%,
    100% 50%,
    100% 101%,
    -1px 101%,
    -1px 50%,
    -1px 0%
  );
}

.btnWithHoverArrow:hover,
.btnWithHoverArrow:hover::before,
.btnWithHoverArrow:hover::after {
  opacity: 1;
  background-color: inherit;
}

.btnWithHoverArrow:hover::before {
  -webkit-clip-path: polygon(
    11px 0%,
    11px 50%,
    11px 101%,
    0% 101%,
    100% 50%,
    0% 0%
  );
  clip-path: polygon(11px 0%, 11px 50%, 11px 101%, 0% 101%, 100% 50%, 0% 0%);
}

.btnWithHoverArrow:hover::after {
  -webkit-clip-path: polygon(
    0% 0%,
    100% 50%,
    0% 101%,
    -1px 101%,
    -1px 50%,
    -1px 0%
  );
  clip-path: polygon(0% 0%, 100% 50%, 0% 101%, -1px 101%, -1px 50%, -1px 0%);
}
</style>
